<template>
<div class="settings-page">
  <!-- المتون  -->
  <b-card class="iq-border-radius-10 mb-5">
    <div class="setting-box d-flex align-items-center justify-content-between gap_1">
      <div class="setting-box-inner d-flex align-items-center gap_2">
        <h6 class="setting-box-title">عدد المتون المسموح بها فى البوليصة الواحدة</h6>
        <div class="counter-box border d-flex align-items-center">
          <button type="button" class="counter-box-btn bg-transparent border-0" @click="decrease">-</button>
          <span class="counter-number text-center">{{matone.matoneNumber}}</span>
          <button type="button" class="counter-box-btn bg-transparent border-0" @click="increase">+</button>
        </div>
      </div>
      <div class="setting-box-inner d-flex align-items-center gap_2">
        <h6 class="setting-box-title">اقصى قيمة للمتون</h6>
        <div class="d-flex align-items-center gap_1">
          <input-form
            class="text-center m-0 matone-value"
            v-model="matone.value"
            validate="required"
            name="value" />
          <span class="small-text">ريال سعودى</span>
        </div>
      </div>
    </div>
  </b-card>
  <b-card class="iq-border-radius-10 mb-5">
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(addNewRow)">
        <template v-slot:header>
          <h5 class="text-primary">التسعيرات</h5>
        </template>
        <div v-for="(price, index) in matone.prices" :key="index" class="d-flex align-items-center gap_5 mb-4">
          <div class="d-flex align-items-center gap_3">
            <div class="d-flex align-items-center gap_2">
              <h6 class="setting-box-title">من</h6>
              <div class="inp-box">
                <b-input-group append="كم" v-if="index === 0">
                  <b-form-input placeholder="0" v-model="price.from" :disabled="price.disabled"></b-form-input>
                </b-input-group>
                <validation-provider
                  v-else
                  #default="{ errors }"
                  :name="`pricesFrom${index}`"
                  :rules="`required|numeric|min_value:${parseInt(matone.prices[index - 1].to) + 1}`"
                >
                  <b-input-group append="كم">
                    <b-form-input placeholder="0" v-model="price.from" :disabled="price.disabled"></b-form-input>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ price.error }}</small>
                </validation-provider>
              </div>
            </div>
            <div class="d-flex align-items-center gap_2">
              <h6 class="setting-box-title">الى</h6>
              <div class="inp-box">
                <validation-provider
                  #default="{ errors }"
                  :name="`pricesTo${index}`"
                  :rules="`required|numeric|min_value:${parseInt(matone.prices[index].from) + 1}`"
                >
                  <b-input-group append="كم">
                    <b-form-input placeholder="0" v-model="price.to"></b-form-input>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="setting-box-inner d-flex align-items-center gap_2">
            <h6 class="setting-box-title">تكون النسبة</h6>
            <validation-provider
              #default="{ errors }"
              :name="`pricesprice${index}`"
              rules="required|numeric"
            >
              <input-form
                class="text-center m-0 matone-value"
                v-model="price.precent"
                validate="required"
                name="pricesprice"
                placeholder="0" />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
        <button type="submit" class="border-0 bg-transparent p-0 add-other-field"> + اضافة مجال اخر </button>
      </b-form>
    </ValidationObserver>
  </b-card>
  <b-card class="iq-border-radius-10 mb-5">
    <b-row class="align-items-center mb-4">
      <b-col cols="3">
        <h6 class="setting-box-title">قيمة التحمل</h6>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center gap_5 position-relative">
          <input-form
            class="text-center m-0 matone-value"
            v-model="matone.mount"
            validate="required"
            name="mount" />
          <span class="small-text position-absolute precent">%</span>
          <h6 class="setting-box-title">من المبلغ المؤمن عليه لأي وكل خسارة تقع لأي متن</h6>
        </div>
      </b-col>
    </b-row>
    <b-row class="align-items-center mb-4">
      <b-col cols="3">
        <h6 class="setting-box-title">الحد الأدنى لقيمة التأمين</h6>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center gap_5">
          <input-form
            class="text-center m-0 matone-value"
            v-model="matone.minmount"
            validate="required"
            name="minmount" />
          <h6 class="setting-box-title">ريال سعودى</h6>
        </div>
      </b-col>
    </b-row>
    <b-row class="align-items-center mb-4">
      <b-col cols="3">
        <h6 class="setting-box-title">رسوم الشهادة</h6>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center gap_5">
          <input-form
            class="text-center m-0 matone-value"
            v-model="matone.certificate"
            validate="required"
            name="certificate" />
          <h6 class="setting-box-title">ريال سعودى</h6>
        </div>
      </b-col>
    </b-row>
    <b-row class="align-items-center mb-4">
      <b-col cols="3">
        <h6 class="setting-box-title">ضريبة القيمة المضافة</h6>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center gap_5 position-relative">
          <input-form
            class="text-center m-0 matone-value"
            v-model="matone.tax"
            validate="required"
            name="tax" />
          <span class="small-text position-absolute precent">%</span>
        </div>
      </b-col>
    </b-row>
  </b-card>
  <div class="d-flex align-items-center justify-content-center">
    <b-button variant="primary" class="setting-save-btn"  type="submit"> حفظ</b-button>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      matone: {
        matoneNumber: 1,
        value: 500000,
        mount: 20,
        minmount: 300,
        certificate: 24,
        tax: 15,
        prices: [
          { from: 0, to: '', precent: '', disabled: true, error: '' }
        ]
      }
    }
  },
  methods: {
    addNewRow () {
      this.matone.prices.push({ from: '', to: '', precent: '' })
    },
    decrease () {
      if (this.matone.matoneNumber > 1) {
        this.matone.matoneNumber--
      }
    }
  }
}
</script>
